
















































import { DomainModel, useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS } from '@/module/api/domo';
import { ExportModel, autoGeneratedLabelName, useExportApi } from '@/module/api/export';
import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface({ maxToasts: 3 });

const { getItem: getDomain } = useDomainApi();

const { createItem: createExportJob, queue: queueExportJob, isLoading: isFetchingDomain } = useExportApi();

const emptyState = () => {
  return {
    dialog: false,
    title: 'New Snapshot',
    label: autoGeneratedLabelName(),
    domainUuid: '',
    domainName: '',
  };
};

const state = reactive(emptyState());

const show = () => {
  state.dialog = true;
};

const isLoading = ref<boolean>(false);

const reset = () => {
  Object.assign(state, emptyState());
};

const initializeData = async (domain: DomainModel) => {
  state.domainUuid = domain.uuid;
  state.domainName = domain.label;
};

const add = (domain: DomainModel) => {
  Vue.$log.debug('Opening export create and run dialog for  domain ', domain);
  state.dialog = false;
  reset();
  initializeData(domain);
  show();
};

const createExportJobItem = async (domainUuid: string) => {
  const exportJobParams: ExportModel = {
    label: state.label,
    domainUuid,
    isRestorePoint: true,
  };

  return await createExportJob(exportJobParams);
};

export function useExportJobSetupDialog() {
  return {
    add,
    show,
    reset,
    isLoading,
  };
}

export default defineComponent({
  name: 'ExportJobSetupDialog',
  emits: [
    'dialog:export:running',
    'dialog:export:saved',
    'dialog:export:cancelled',
    'dialog:export:requestRefresh',
    'dialog:export:errored',
  ],
  setup(props, context) {
    const emitRefreshItems = () => {
      context.emit('dialog:export:requestRefresh');
    };

    const emitSaved = () => {
      context.emit('dialog:export:saved');
    };

    const emitCancelled = () => {
      context.emit('dialog:export:cancelled');
    };

    const emitErrored = (errorMessage?: string) => {
      context.emit('dialog:export:cancelled', errorMessage);
    };

    const cancel = () => {
      state.dialog = false;
      emitCancelled();
    };

    const runExportJob = async (): Promise<ExportModel | undefined> => {
      if (!state.domainUuid) {
        const errMessage = 'Cannot start a Snapshot without a Domain';
        Vue.$log.error(errMessage);
        toast.error(errMessage);
        emitErrored(errMessage);
        return;
      }
      try {
        isLoading.value = true;
        const exportJob = await createExportJobItem(state.domainUuid);
        if (!exportJob || !exportJob?.uuid) {
          const errMessage = 'Unable to create SNapshot.';
          toast.error(errMessage);
          emitErrored(errMessage);
          return;
        }
        await queueExportJob(exportJob.uuid);
        await getDomain(exportJob.domainUuid);
        emitSaved();
        toast.success('Started Snapshot. This will take a while.');
        reset();
        return exportJob;
      } catch (err) {
        const errMessage = 'Unable to start Snapshot.';
        Vue.$log.error(errMessage, err);
        toast.error(errMessage);
        emitErrored(errMessage);
        return undefined;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      ...toRefs(state),
      reset,
      show,
      add,
      cancel,
      runExportJob,
      isLoading,
      isFetchingDomain,
      DOMO_EXPIRATION_TTL_DAYS,
    };
  },
});
