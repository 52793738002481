var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            persistent: "",
            width: "45vw",
            scrollable: "",
            lass: "no-x"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { loading: _vm.isLoading } },
            [
              _c("v-card-title", [_vm._v(" New Snapshot ")]),
              _c(
                "v-card-text",
                [
                  _c("p", [
                    _vm._v(
                      " Creating a Snapshot will perform a read-only operation to extract configurations for this Domain. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "Data will be stored for " +
                        _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
                        " days."
                    )
                  ]),
                  _c(
                    "v-list-item",
                    { staticClass: "px-0", attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Domain")]),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(_vm.domainName))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              attrs: {
                                disabled: _vm.isLoading || _vm.isFetchingDomain
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { hidden: "" } },
                                [_c("v-text-field")],
                                1
                              ),
                              _c("v-text-field", {
                                staticClass: "mt-6",
                                attrs: { outlined: "", label: "Snapshot Name" },
                                model: {
                                  value: _vm.label,
                                  callback: function($$v) {
                                    _vm.label = $$v
                                  },
                                  expression: "label"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled: _vm.isLoading || _vm.isFetchingDomain,
                        text: ""
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading || _vm.isFetchingDomain
                      },
                      on: { click: _vm.runExportJob }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }